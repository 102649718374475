<template>
  <legal-page-template :locale="locale">
    <main class="legal-page__content legal-page-content">
      <h1 class="legal-page-content__title">{{ $t('legal.privacyPatients.title') }}</h1>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header legal-page-article__header--with-margin">
          {{ $t('legal.privacyPatients.ourEngagement') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.privacyPatients.privacyPolicyIntended"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.privacyPatients.you') }}</b>
            <b>{{ $t('legal.privacyPatients.user') }}</b>
            <b>UNIVERSKIN</b>
          </i18n>

          <i18n
            tag="p"
            path="legal.privacyPatients.yourPersonalData"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.privacyPatients.regulationsApplicableToProtection') }}</b>
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.whenItCollectsAndProcesses') }}
          </p>

          <i18n
            tag="p"
            path="legal.privacyPatients.theSiteUniverskin"
            class="legal-article-section__paragraph"
          >
            <a class="legal-page-content__link" href="https://www.universkin.com" target="_blank"
              >www.universkin.com</a
            >
            <b>{{ $t('legal.privacyPatients.site') }}</b>
          </i18n>

          <ul class="legal-article-section__list list list--lower-roman-style">
            <i18n
              tag="li"
              path="legal.privacyPatients.obtainAnAutomatedAnalysis"
              class="legal-article-section__paragraph"
            >
              <b>{{ $t('legal.privacyPatients.tool') }}</b>
            </i18n>
            <i18n
              tag="li"
              path="legal.privacyPatients.ifYouDoNotWish"
              class="legal-article-section__paragraph"
            >
              <b>{{ $t('legal.privacyPatients.questionnaire') }}</b>
            </i18n>

            <i18n
              tag="li"
              path="legal.privacyPatients.OrderProducts"
              class="legal-article-section__paragraph"
            >
              <b>{{ $t('legal.privacyPatients.product') }}</b>
            </i18n>

            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.bePutInTouch') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.andMoreGenerally') }}
            </li>
          </ul>

          <i18n
            tag="p"
            path="legal.privacyPatients.theSkinxsPaltform"
            class="legal-article-section__paragraph"
          >
            <a class="legal-page-content__link" href="https://skinxs.com/" target="_blank"
              >https://skinxs.com/</a
            >
            <b>{{ $t('legal.privacyPatients.platform') }}</b>
          </i18n>

          <ul class="legal-article-section__list list list--lower-roman-style">
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.establishDermatologicalDiagnosis') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.adjustOrEstablish') }}
            </li>
          </ul>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.youAreInformed') }}
          </p>

          <i18n
            tag="p"
            path="legal.privacyPatients.theUniveskinPlusSite"
            class="legal-article-section__paragraph"
          >
            <a
              class="legal-page-content__link"
              href="https://prescriptionskincare.skinxs.com"
              target="_blank"
              >https://prescriptionskincare.skinxs.com</a
            >

            <b>{{ $t('legal.privacyPatients.doctorWebshop') }}</b>
            <a
              class="legal-page-content__link"
              href="https://skinxs.com/terms-of-sales"
              target="_blank"
              >{{ $t('legal.privacyPatients.generalConditionsOfSale') }}</a
            >
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">1.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.purposesOfProcessingPersonalData') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinMayAccess') }}
          </p>

          <ul class="legal-article-section__list list list--dash-style">
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.processingManager') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.jointController') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.subContractor') }}
            </li>
          </ul>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinUndertakes') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">2.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.mappingOfUsersPersonalData') }}
        </div>

        <div
          class="legal-page-article__section legal-article-section legal-article-section--horizontal-scroll"
        >
          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            2.1. {{ $t('legal.privacyPatients.treatmentsOperatedOnSite') }}
          </p>
          <u-table class="legal-article-section__table" :table-config="siteTreatmentsData">
            <template #cellContent="{ cellContent }">
              <ul v-if="cellContent.list">
                <li
                  v-for="(listItem, index) of cellContent.list"
                  :key="getTableListItemKey(listItem, index)"
                >
                  <span>{{ listItem.text }}</span>
                  <ul v-if="listItem.subList">
                    <li v-for="sublistItem of listItem.subList" :key="sublistItem">
                      {{ sublistItem }}
                    </li>
                  </ul>
                  <span v-else>{{ listItem }}</span>
                </li>
              </ul>

              <span v-else> {{ cellContent.text }}</span>
            </template>
          </u-table>
        </div>

        <div
          class="legal-page-article__section legal-article-section legal-article-section--horizontal-scroll"
        >
          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            2.2. {{ $t('legal.privacyPatients.treatmentsOperatedOnPlatform') }}
          </p>

          <u-table class="legal-article-section__table" :table-config="platformTreatmentsData">
            <template #cellContent="{ cellContent }">
              <ul v-if="cellContent.list">
                <li
                  v-for="(listItem, index) of cellContent.list"
                  :key="index + listItem.toString()"
                >
                  <ul v-if="listItem.subList">
                    <span>{{ listItem.text }}</span>
                    <li v-for="sublistItem of listItem.subList" :key="sublistItem">
                      {{ sublistItem }}
                    </li>
                  </ul>
                  <span v-else>{{ listItem }}</span>
                </li>
              </ul>

              <span v-else> {{ cellContent.text }}</span>
            </template>
          </u-table>
        </div>

        <div
          class="legal-page-article__section legal-article-section legal-article-section--horizontal-scroll"
        >
          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            2.3. {{ $t('legal.privacyPatients.treatmentsOperatedOnWebshop') }}
          </p>

          <u-table class="legal-article-section__table" :table-config="webshopTreatmentsData">
            <template #cellContent="{ cellContent }">
              <ul v-if="cellContent.list">
                <li v-for="(listItem, index) of cellContent.list" :key="index + listItem">
                  <ul v-if="listItem.subList">
                    <span>{{ listItem.text }}</span>
                    <li v-for="sublistItem of listItem.subList" :key="sublistItem">
                      {{ sublistItem }}
                    </li>
                  </ul>
                  <span v-else>{{ listItem }}</span>
                </li>
              </ul>

              <span v-else> {{ cellContent.text }}</span>
            </template>
          </u-table>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">3.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.shelfLife') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            3.1 {{ $t('legal.privacyPatients.retentionOfDataOnSite') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.personalDataProcessed') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.personalDataCollected') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinKeepsEmailAddress') }}
          </p>

          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            3.2 {{ $t('legal.privacyPatients.retentionOfDataOnPlatform') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinKeepsPersonalData') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.personalDataProcessedUntil') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.whenUniverskinActs') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.userIsInformed') }}
          </p>

          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            3.3 {{ $t('legal.privacyPatients.retentionOfDataOnWebshop') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinKeepsPersonalData') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinKeepsPersonalDataUntil') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.userIsInformed') }}
          </p>

          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            3.4 {{ $t('legal.privacyPatients.archiving') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.beyondAboveMentionedPeropds') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">4.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.recipientsOfPersonalData') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinWillNotHaveAccess') }}
          </p>

          <ul class="legal-article-section__list list list--lower-roman-style">
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.universkinPhysicians') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.byTechnicalTeams') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.usedForScientificResearch') }}
            </li>
          </ul>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.onlyTheDoctorChosen') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.legalOrJudicialObligation') }}
          </p>

          <ul class="legal-article-section__list list list--dash-style">
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.hostingProvidersOfSite') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.universkinProvidesAuthorized') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.paymentServiceProviders') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.ifUniverskinInvolvedInMerger') }}
            </li>
            <li class="legal-article-section__paragraph">
              {{ $t('legal.privacyPatients.thirdPartyCookie') }}
            </li>
          </ul>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.personalDataResiding') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">5.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.securityMeasuresImplemented') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinSeeksToImplementAndMaintain') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.siteHostedByCompany') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.platformHostedByCompany') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.inAccordanceWithRegulations') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">6.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.rightsOfUserOverHisPersonalData') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.youHaveOptionToRequest') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.rightsOfAccess') }}</b>
            {{ $t('legal.privacyPatients.youEntitledToAskUniverskin') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.rightToRectify') }}</b>
            {{ $t('legal.privacyPatients.youHaveRightToAskUniverkin') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.rightToLimitProcessing') }}</b>
            {{ $t('legal.privacyPatients.youCanAskUniverskinThatComeOfYourData') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.rightToDelete') }}</b>
            {{ $t('legal.privacyPatients.obtainErasureOfHisPersonalData') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.rightToDataPortability') }}</b>
            {{ $t('legal.privacyPatients.youCanObtainCommunication') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.rightOfOpposition') }}</b>
            {{ $t('legal.privacyPatients.toObjectAtAnyTimeForReasons') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.withdrawalOfConsent') }}</b>
            {{ $t('legal.privacyPatients.withdrawYourConsent') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>&bull; {{ $t('legal.privacyPatients.rightToSubmitComplaint') }}</b>
            {{ $t('legal.privacyPatients.lodgeComplaintWithCompetentData') }}
          </p>

          <i18n
            tag="p"
            path="legal.privacyPatients.inOrderToAllowExercise"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:dpo@universkin.com" class="legal-page-content__link"
              >dpo@universkin.com</a
            >
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">7.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.hyperTextLinks') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.mayContentHypertextLinks') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.userNotAuthorized') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">8.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.managementOfCookiesAndSocialNetworks') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinUsesCookies') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.informationCollectedThroughCookies') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.universkinUsesFollowingCookies') }}
          </p>

          <div class="legal-page-table">
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">
                {{ $t('legal.privacyPatients.performanceCookies') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyPatients.performanceCookiesDescription') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">
                {{ $t('legal.privacyPatients.cookieName') }}
              </div>
              <div class="legal-page-table__title">
                {{ $t('legal.privacyPatients.storagePeriod') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyPatients.cookies.liveChat') }}
              </div>
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyPatients.cookies.threeYears') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyPatients.cookies.sentry') }}
              </div>
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyPatients.cookies.threeYears') }}
              </div>
            </div>
          </div>

          <div class="legal-page-table">
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">
                {{ $t('legal.privacyPatients.statisticsCookies') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyPatients.statisticsCookiesDescription') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">
                {{ $t('legal.privacyPatients.cookieName') }}
              </div>
              <div class="legal-page-table__title">
                {{ $t('legal.privacyPatients.storagePeriod') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyPatients.cookies.gid') }}
              </div>
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyPatients.cookies.oneDay') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyPatients.cookies.ga') }}
              </div>
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyPatients.cookies.fourteenMonths') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyPatients.cookies.gat') }}
              </div>
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyPatients.cookies.oneDay') }}
              </div>
            </div>
          </div>

          <i18n
            tag="p"
            path="legal.privacyPatients.userFreeToConsent"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.userCanAlsoConfigure') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.browserConfig.windowsInternetExplorer') }}<br />
            <a
              href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
              class="legal-page-content__link"
              target="_blank"
              >https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.browserConfig.mozillaFirefox') }}
            <a
              href="https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites"
              class="legal-page-content__link"
              target="_blank"
              >https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.browserConfig.googleChrome') }}
            <a
              href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DiOS&hl=fr"
              class="legal-page-content__link"
              target="_blank"
              >https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DiOS&hl=fr</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.browserConfig.appleSafariIPhoneIPad') }}
            <a
              href="https://support.apple.com/fr-fr/HT201265"
              class="legal-page-content__link"
              target="_blank"
              >https://support.apple.com/fr-fr/HT201265</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.browserConfig.appleSafariMac') }}
            <a
              href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
              class="legal-page-content__link"
              target="_blank"
              >https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.browserConfig.deacticationOfGoogleAnalytics') }}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              class="legal-page-content__link"
              target="_blank"
              >https://tools.google.com/dlpage/gaoptout</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.siteUsesFollowingSocialMediaPlugins') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMedia.facebookAndInstagram') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMedia.linkedin') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMedia.youtube') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMedia.pinterest') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.whenUserInteractsUsingThesePlugins') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.inAnyEventUniverskinHasNoControl') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMediaTerms.forFacebook') }}
            <a
              href="https://fr-fr.facebook.com/policies/"
              class="legal-page-content__link"
              target="_blank"
              >https://fr-fr.facebook.com/policies/</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMediaTerms.forYoutube') }}
            <a
              href="https://support.google.com/youtube/answer/9315727?hl=fr"
              class="legal-page-content__link"
              target="_blank"
              >https://support.google.com/youtube/answer/9315727?hl=fr</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMediaTerms.forPinterest') }}
            <a
              href="https://policy.pinterest.com/fr/privacy-policy"
              class="legal-page-content__link"
              target="_blank"
              >https://policy.pinterest.com/fr/privacy-policy</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMediaTerms.forLinkedin') }}
            <a
              href="https://www.linkedin.com/help/linkedin/answer/1828/supprimer-le-cache-et-les-cookies?lang=fr"
              class="legal-page-content__link"
              target="_blank"
              >https://www.linkedin.com/help/linkedin/answer/1828/supprimer-le-cache-et-les-cookies?lang=fr</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyPatients.socialMediaTerms.forInstagram') }}
            <a
              href="https://help.instagram.com/1896641480634370"
              class="legal-page-content__link"
              target="_blank"
              >https://help.instagram.com/1896641480634370</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.trademarks.facebookAndInstagram') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.trademarks.pinterest') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.trademarks.linkedin') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyPatients.trademarks.youtube') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">8.</div>
        <div class="legal-page-article__title">
          {{ $t('legal.privacyPatients.evolutionOfPrivacyPolicy') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.privacyPatients.thisPrivacyPolicyCanBeModified"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>
        </div>
      </div>
    </main>
  </legal-page-template>
</template>

<script>
import { UTable } from 'universkin-shared';

import LegalPageTemplate from '@/components/templates/LegalPageTemplate';

import {
  SITE_TREATMENT_DATA,
  PLATFORM_TREATMENT_DATA,
  WEBSHOP_TREATMENT_DATA
} from '@/modules/dashboard/utils/legal';

export default {
  name: 'PagePatientNotice',
  components: { LegalPageTemplate, UTable },
  props: {
    locale: {
      type: String,
      default: ''
    }
  },
  metaInfo: {
    title: 'Privacy Notice - Patients',
    titleTemplate: 'skinXs - %s'
  },
  computed: {
    siteTreatmentsData() {
      return SITE_TREATMENT_DATA(this.$t);
    },
    platformTreatmentsData() {
      return PLATFORM_TREATMENT_DATA(this.$t);
    },
    webshopTreatmentsData() {
      return WEBSHOP_TREATMENT_DATA(this.$t);
    }
  },
  methods: {
    getTableListItemKey(listItem, index) {
      const { text } = listItem;

      return text ? `${text}-${index}` : `${listItem}-${index}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-pages/content-styles';

.legal-page-content {
  font-family: var(--u-font-family-Helvetica);
}
</style>
