const SITE_TREATMENT_DATA = $t => ({
  headers: [
    $t('legal.privacyPatients.responsibilities'),
    $t('legal.privacyPatients.purpose'),
    $t('legal.privacyPatients.legalBasis'),
    $t('legal.privacyPatients.categoriesOfData')
  ],
  rows: [
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.establishEstablishAnalysis') },
      { text: $t('legal.privacyPatients.consent') },
      {
        list: [
          {
            text: $t('legal.patientConsent.locationInformation'),
            subList: [$t('legal.privacyPatients.location.yourRegionOfResidence')]
          },
          {
            text: $t('legal.patientConsent.healthInformation'),
            subList: [
              $t('legal.privacyPatients.healthInfo.characteristicsOfYourFace'),
              $t('legal.privacyPatients.healthInfo.ethnicOrigin'),
              $t('legal.privacyPatients.healthInfo.ifNecessaryAlterations'),
              $t('legal.privacyPatients.healthInfo.yourMedicalHistory'),
              $t('legal.privacyPatients.healthInfo.familyHistory'),
              $t('legal.privacyPatients.healthInfo.ifApplicableCare'),
              $t('legal.privacyPatients.healthInfo.yourLifestyle')
            ]
          },
          $t('legal.privacyPatients.healthInfo.photosOfYourFace')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.dataAnnotationAndLabeling') },
      { text: $t('legal.privacyPatients.consent') },
      { text: $t('legal.privacyPatients.qualifyingData') }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.openningAndManagment') },
      { text: $t('legal.privacyPatients.executionOfSiteConditions') },
      {
        list: [
          $t('legal.privacyPatients.contactInfo.yourNameAndSurname'),
          $t('legal.privacyPatients.contactInfo.gender'),
          $t('legal.privacyPatients.contactInfo.dateOfBirth'),
          $t('legal.privacyPatients.contactInfo.emailAddress'),
          $t('legal.privacyPatients.contactInfo.phoneNumber')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.ensureTheOrderTaking') },
      { text: $t('legal.privacyPatients.executionOfSaleOfSiteConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.contactInfo.postalAddress')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.provideAfterSalesService') },
      { text: $t('legal.privacyPatients.executionOfSaleOfSiteConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.contactInfo.postalAddress')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.claimsManagment') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.concernsTheQuality')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.sendTheUniverskin') },
      { text: $t('legal.privacyPatients.consent') },
      {
        list: [$t('legal.privacyPatients.contactInfo.emailAddress')]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.communicateWithTheUser') },
      { text: $t('legal.privacyPatients.consent') },
      {
        list: [
          $t('legal.privacyPatients.contactInfo.lastNameFirstName'),
          $t('legal.privacyPatients.contactInfo.emailAddress'),
          $t('legal.privacyPatients.contactInfo.telephoneNumber'),
          $t('legal.privacyPatients.contactInfo.countryOfResidence'),
          $t('legal.privacyPatients.contactInfo.ifApplicableNameOfCompany'),
          $t('legal.privacyPatients.contactInfo.anyInformation')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.operationalManagment') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.connectionLogs'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.possibleAccess')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.fraudPrevention') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.connectionLogs'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.contactInfo.lastNameFirstName'),
          $t('legal.privacyPatients.contactInfo.emailAddress')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.monitoringAndAnalysisSiteTraffic') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.statisticalDataUsage'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.connectionLogs')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.statistic') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [$t('legal.privacyPatients.statisticalDataUsage')]
      }
    ],
    [
      { text: $t('legal.privacyPatients.universkinWithDoctor') },
      { text: $t('legal.privacyPatients.manageTheRedirection') },
      { text: $t('legal.privacyPatients.executionOfSiteConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.qualifyingData'),
          $t('legal.privacyPatients.IPAdress')
        ]
      }
    ]
  ]
});

const PLATFORM_TREATMENT_DATA = $t => ({
  headers: [
    $t('legal.privacyPatients.responsibilities'),
    $t('legal.privacyPatients.purpose'),
    $t('legal.privacyPatients.legalBasis'),
    $t('legal.privacyPatients.categoriesOfData')
  ],
  rows: [
    [
      { text: $t('legal.privacyPatients.universkinWithDoctor') },
      { text: $t('legal.privacyPatients.managementUsersConnection') },
      { text: $t('legal.privacyPatients.executionOfPlatformConditions') },

      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.connectionLogs')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.universkinWithDoctor') },
      { text: $t('legal.privacyPatients.ensureAndManageConnection') },
      { text: $t('legal.privacyPatients.executionOfPlatformConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.qualifyingData')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.establishmentFromData') },
      { text: $t('legal.privacyPatients.executionOfPlatformConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.qualifyingData')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.automation') },
      { text: $t('legal.privacyPatients.executionOfPlatformConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingDataPseudonymized'),
          $t('legal.privacyPatients.qualifyingData')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.annotationAdLabeling') },
      { text: $t('legal.privacyPatients.platformConditionsAndLegitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.identifyingDataPseudonymized'),
          $t('legal.privacyPatients.qualifyingData')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.respondToUserRequests') },
      { text: $t('legal.privacyPatients.executionOfConditions') },
      {
        list: [
          $t('legal.privacyPatients.contactInfo.emailAddress'),
          $t('legal.privacyPatients.anyPersonalData')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.managementOfAnyDisputes') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.connectionLogs'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.contactInfo.identityDocument')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.operationalManagmentPlatform') },
      { text: $t('legal.privacyPatients.executionOfConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.connectionLogs'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.possibleAccess')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.fraudPrevention') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.connectionLogs'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.contactInfo.lastNameFirstName'),
          $t('legal.privacyPatients.contactInfo.emailAddress')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.monitoringAndAnalysisPlatformTraffic') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.statisticalDataUsage'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.connectionLogs')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.statistic') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [$t('legal.privacyPatients.statisticalDataUsage')]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.unlessTheUserObjects') },
      { text: $t('legal.privacyPatients.processingNecessary') },
      {
        list: [
          $t('legal.privacyPatients.identifyingDataPseudonymized'),
          $t('legal.privacyPatients.qualifyingData')
        ]
      }
    ]
  ]
});

const WEBSHOP_TREATMENT_DATA = $t => ({
  headers: [
    $t('legal.privacyPatients.responsibilities'),
    $t('legal.privacyPatients.purpose'),
    $t('legal.privacyPatients.legalBasis'),
    $t('legal.privacyPatients.categoriesOfData')
  ],
  rows: [
    [
      { text: $t('legal.privacyPatients.universkinWithDoctor') },
      { text: $t('legal.privacyPatients.ensureTheTraceability') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      { text: '' }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.ensureTheOrderTaking') },
      { text: $t('legal.privacyPatients.executionOfSalesConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.shoppingCart'),
          $t('legal.privacyPatients.contactInfo.postalAddress')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.provideAfterSalesService') },
      { text: $t('legal.privacyPatients.executionOfSalesConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.shoppingCart'),
          $t('legal.privacyPatients.contactInfo.postalAddress')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.claimsManagment') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.shoppingCart'),
          $t('legal.privacyPatients.concernsTheQuality')
        ]
      }
    ],
    [
      { text: $t('legal.privacyPatients.doctorUniverskin') },
      { text: $t('legal.privacyPatients.operationalManagmentWebshop') },
      { text: $t('legal.privacyPatients.executionOfConditions') },
      {
        list: [
          $t('legal.privacyPatients.identifyingData'),
          $t('legal.privacyPatients.shoppingCartAndPostalAddress')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.fraudPrevention') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.connectionLogs'),
          $t('legal.privacyPatients.IPAdress'),
          $t('legal.privacyPatients.contactInfo.lastNameFirstName'),
          $t('legal.privacyPatients.contactInfo.emailAddress')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.platformMonitoring') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [
          $t('legal.privacyPatients.statisticalDataUsage'),
          $t('legal.privacyPatients.connectionLogs'),
          $t('legal.privacyPatients.IPAdress')
        ]
      }
    ],
    [
      { text: 'UNIVERSKIN' },
      { text: $t('legal.privacyPatients.statistic') },
      { text: $t('legal.privacyPatients.legitimateInterest') },
      {
        list: [$t('legal.privacyPatients.statisticalDataUsage')]
      }
    ]
  ]
});

export { SITE_TREATMENT_DATA, PLATFORM_TREATMENT_DATA, WEBSHOP_TREATMENT_DATA };
